// Access to stripe js embedded components

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {

        const stripe = Stripe(this.data.get("key"));
        const url = this.data.get("url"); // url to retrieve the checkout session

initialize();

        async function initialize() {
            const fetchClientSecret = async () => {
                const response = await fetch(url, {
                    method: "POST",
                });
                const {clientSecret} = await response.json();
                return clientSecret;
            };

            const checkout = await stripe.initEmbeddedCheckout({
                fetchClientSecret,
            });

            // Mount Checkout
            checkout.mount('#checkout');
        }

    }
}
