// for the table rows in the operators flight application page
// monitors the flight channel
// also allows to activate and deactivate

import { Controller } from "@hotwired/stimulus"
import consumer from '../channels/consumer';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    // console.log('Flight activation controller connected');
    this.channel = consumer.subscriptions.create('FlightChannel', {
      connected: this._cableConnected.bind(this),
      disconnected: this._cableDisconnected.bind(this),
      received: this._cableReceived.bind(this),
    });

    // console.log('flight activation controller is connected')
    let _this = this;
    let ids = this.buttonTargets.map( button => button.dataset.flight)
    $.ajax({
      url: '/operator/flight_applications/activation_status?ids=' + ids,
      cache: false,
      dataType: 'json',
      success: function(data){
        // console.log('data received: ', data);
        // console.log('data type is: ', typeof(data));
        for (let id in data) {
          // console.log('id received: ', id);
          let element = document.getElementById(`flight-${id}`);
          // console.log('element is: ', element);
          if (data[id]['approved_or_acknowledged'] === 'true') { // if false leave everything hidden
            if (data[id]['can_be_activated'] === 'true') { // can be activated so show a button
              _this.set_element_status(element, (data[id]['activated'] === 'true'));
            } else { // hide it all except placeholder
              element.classList.remove('flight-activated');
              element.querySelector(`.active-link`).classList.add('d-none');
              element.querySelector(`.inactive-link`).classList.add('d-none');
              let placeholder = element.querySelector(`.activation-placeholder`)
              if (placeholder) {
                placeholder.classList.remove('d-none');
              }
            }
          }
        }
      },
      error: function(){
        console.log('oh no')
      }
    });
  }

  set_element_status(element, active) {
    let activeLink = element.querySelector(`.active-link`);
    let inactiveLink = element.querySelector(`.inactive-link`);
    let placeholder = element.querySelector(`.activation-placeholder`);
    if (active) {
      element.classList.add('flight-activated');
      element.dataset.activated = 'true';
      activeLink.classList.add('d-none');
      inactiveLink.classList.remove('d-none');
    } else {
      element.classList.remove('flight-activated');
      element.dataset.activated = 'false';
      activeLink.classList.remove('d-none');
      inactiveLink.classList.add('d-none');
    }
    // whether active or not, hide the placeholder if present
    if (placeholder) {
      placeholder.classList.add('d-none');
    }
  }

  activate(event) {
    event.preventDefault();
    return $.ajax({
      url: event.target.dataset.url,
      cache: false,
      dataType: 'script'
    });
  }

  deactivate(event) {
    event.preventDefault();
    return $.ajax({
      url: event.target.dataset.url,
      cache: false,
      dataType: 'script'
    });
  }

  _cableReceived(data) {
    // Called when there's incoming data on the websocket for this channel
    console.log('data received');
    const flightId = data.flight;
    const element = document.getElementById(`flight-${flightId}`);
    if (element) {
      this.set_element_status(element, (data.status == 'active'))
    }
  }

  _cableConnected() {
    // Called when the subscription is ready for use on the server
  }

  _cableDisconnected() {
    // Called when the subscription has been terminated by the server
  }

}
